.ProsphoricPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 3vw 40px 3vw;
}

.ProsphoricPageSection1{
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    justify-content: center;
}

.ProsphoricPageSection1Text{
    
    border-radius: 5px;
    margin-right: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50%;
   
}
.Text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 49px;
    display: flex;
    flex-direction: column;
    align-items: center;
    letter-spacing: 0.05em;
    
    width: 80%;
    color: #000000;
}
.Images{
    min-width: 30%;
    display: block;
}
.Img1{
    border-radius: 10px;
    width: 80%;
    object-fit: contain;
}


.Title{
    font-family: 'Vezitsa';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 26px;
    color: #000000;
    margin: 10px 0 30px 5px;
}

@media (max-device-width: 500px) {
    .ProsphoricPageSection1{
        flex-flow: column nowrap;
    }
    .ProsphoricPageSection1Text{
        max-width: 94vw;
    }
    .Text{
        max-width: 94vw;
        width: 90%;
        font-size: 16px;
        align-items: start;
    }
    .Images{
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-around;
        width: 94vw;
    }
    .Img1{
        object-fit: cover;
        width: 40vw;
        
    }
}