.slider {
    position: relative;
    display: flex;
    justify-content: center;
    
}
 
.slider div  {
    border-radius: 10px;
   
}

@media (max-device-width: 500px) {
    .slider{
        margin: 100px 0 120px 0;
        
    }
}