.MercyBusPage{
    display: flex;
    flex-direction: column;
    margin: 20px 3vw 20px 3vw;
    align-items: center;
}
.Title{
    font-family: 'Vezitsa';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    margin: 40px 0 40px 0; 
}
.Section{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 40px 0 20px 0;
}
.Img{
    border-radius: 10px;
    object-fit: cover;
    width: 100%;
    min-width: 250px;
    max-width: 50vw;
}

.Texts{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 40vw;
    min-width: 300px;
}


.Text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 37px;
    display: flex;
    align-items: center;

    color: #000000;

}
.Text1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 37px;
    display: flex;
    align-items: center;

    color: #000000;
}
.Button{
    width: 35vw;
    min-width: 300px;
    margin: 40px 0 40px 0;
    background: #DF6033;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    text-decoration: none;
    transition: 700ms;
    text-align: center;

}
.Button:hover{
    transition: 700ms;
    background: #c93600;
}
.WhiteText{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 37px;
    color: #FFFFFF;
}
@media (max-width: 500px) {
    .WhiteText{
        font-size: 22px;
        line-height: 20px;
    }

}
.DressAndFood{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}
.ImgDressAndFood{
    margin: 20px 0 20px 0;
    min-width: 300px;
    width: 45vw;
    border-radius: 10px;
    object-fit: cover;
}
.Bustime{
    align-self: center;
    width: 94vw;
    object-fit: cover;
    margin: 0 0 0 3vw;
}

@media (max-device-width: 500px) {
    
    .Section:nth-child(3){
        display: flex;
        flex-direction: column-reverse;
    }
    .Texts{
        max-width: 100%;
        width: 94vw;
        font-size: 20px;
    }
    .Title{
        font-size: 60px;
    }
    .WhiteText{
        font-size: 35px;
        line-height: 20px;
    }

    .Text{
        font-size: 10pt;
    }
    
    .Button{
        width: 60vw;
        height: 10vw;
    }
    .Img{
        width: 96vw;
        max-width: none;
        margin: 10px 0 10px 0;
    }
    .Text1{
        font-size: 21pt;
    
    }
}