.Images{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 3vw 40px 3vw;
}
.Title{
    margin: 20px 0 20px 0;
    font-family: 'Vezitsa';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 26px;
    color: #000000;
}
.Img{
    margin: 20px 0 20px 0;
    object-fit: contain;
    max-width: min-content;
    width: 70%;
}

@media (max-device-width:500px) {
    .Img{
        margin: 20px 0 20px 0;
        object-fit: contain;
        max-width: min-content;
        width: 100%;
    }
}