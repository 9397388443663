.LibraryPage{
    display: flex;
    flex-direction: column;
    align-items: center;

}
.Title{ 
    margin: 40px 0 40px 0;
    font-family: 'Vezitsa';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 26px;
    color: #000000;
}
.Img1{
    max-width: 94vw;
    margin: 40px 3vw 40px 3vw;
    border-radius: 10px;
    object-fit: contain;
}
.Section2{
    background: #FFFFFF;
    border-radius: 10px;

}
.Section2inside{
    margin: 20px 3vw 40px 3vw;
    display: flex;
    flex-flow: row nowrap;
}
.Img2{
    max-width: 50%;
    border-radius: 10px;
    object-fit: cover;
    min-width: 300px;
}
.Text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: right;
    max-width: 700px;
    text-align: left;
    margin: 0 0 0 10vw;
}
@media (max-width: 1520px) {
    .Text{
        max-width: 400px;
    }
}
@media (max-width: 900px) {
    .Section2inside{
        justify-content: center;
        align-items: center;
        margin: 0 5px 0 5px;
    }
    .Img2{
        max-width: 100%;
    }
    .Text{
        width: 100%;
    }
    .Img1{
        margin: 20px 5px 20px 5px;
    }
}
@media (max-device-width: 500px) {
    .Text{
        width: 94wv;
        
    }
}