.footer{
    display: flex;
    flex-flow: column nowrap;
    background: #545151;
    
    
}
.logoes{
    display: flex;
    flex-flow: wrap row;
    text-align: center;
    justify-content: space-around;
    margin: 10px 0 10px 0;
}

.logoOmsk{
    
}

.logoRussia{
   
}

.copyright{
    margin: 15px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.copyrightText{
    margin: 5px 0 0 0 ;
    font-family: 'Vezitsa';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    
    color: #FFFFFF;
}

@media  ( max-device-width: 490px) {
    .logoRussia{
        width: 50vw;
        object-fit: contain;
    }
    .logoOmsk{
        width: 50vw;
        object-fit: contain;
    }    
    .logoes{
        display: flex;
        flex-flow: wrap column;
        text-align: center;
        justify-content: space-around;
        margin: 10px 0 10px 0;
        min-height: 450px;
    }
    .copyrightText{
        font-size: 18px;
    }
    .copyrightTextSign{
        font-size: 18px;
    }
}