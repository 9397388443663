

.menuItem {
    font-family: 'Vezitsa';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-decoration: none;
    color: #000000;
}
.active {
    display: none;
    color: #DF6033;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-decoration: none;
    text-align: center;
   
    
}
.inActive{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
    color: black;
    text-decoration: none;
    text-align: center;
   
    
}
.itemTitle{
    max-width: max-content;
    height: min-content;
}

@media  ( max-device-width: 400px){
    .menuItem{
        font-size: 27px;
    }
    .inActive{
        font-size: 30px;
        margin: 10px;
    }
}