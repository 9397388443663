.HeatingPoint{
    margin: 20px 3vw 20px 3vw;
}
.Title {
    font-family: 'Vezitsa';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 26px;
    color: #000000;
    margin: 40px 0 80px 5px;
    text-align: center;
}

.insideParallax{
    position: relative;
    width: 94vw;
    height: 50vw;
    
}
.Section2{
    display: flex;
    flex-direction: column;
    margin: 80px 0 0 0 ;
    height: 50vh;
}

.Section2Text1{
    display: flex;
    flex-direction: row;
    width: 94vw;
    
}
.Section2Text1 p{
    max-width: 600px;
    width: 50vw;
}
.Section2Text2{
    display: flex;
    flex-direction: row-reverse;
    width: 94vw;
}
.Section2Text2 p{
    max-width: 600px;
    width: 50vw;
}
.Text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
}
.TextSpeak{
    font-family: 'Montserrat';
    font-style: italic;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
    margin: 0 0 0 20px;
}
.Images{
    width: 50vw;
    border-radius: 10px;
}
.Section3{
    display: flex;
    flex-direction: row;
    margin: 40px 0 40px 0;
}
.Section3 :nth-child(2){
    flex-direction: row-reverse;
    margin: 80px 0 0 0;
}
.Section3Text {
    align-self: center;
    
}
.Text:last-child{
    margin: 0 20px 0 0;
    
}
@media (min-width: 1500px) {
    .Section2{
        height: 20vh;
    }
}
@media (max-device-width:450px) {
    .Text{
        font-size: 14px;
    }
    .TextSpeak{
        font-size: 14px;
    }
    .Section2{
        height: 29vh;

    }
    .Section2Text1 p{
        max-width: none;
        font-size: xx-large;
        line-height: normal;
        width: 100%;
    }
    .Section2Text2 p{
       max-width: none;
       font-size: xx-large;
       line-height: normal;
       width: 100%;
    }
    .Section2Text2 {
        
        margin: 40px 0 0 0;
        
    }
    .Images{
        width: 94vw;
    }
    .Section3{
        flex-direction: column;
    }
    .Section3:last-child{
        flex-direction: column-reverse;
    }
    .Text:last-child{
        margin: 50px 0 0 0;
        
    }
}