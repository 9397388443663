.donateTitle{
    font-family: 'Vezitsa';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 40px;
    color: #E96127;
    text-align: center;
}

.ImgMain{
    position: absolute;
    object-fit: cover;
    width: 99.2vw;
    z-index:-1;
    height: 100%;
    
}
.donateNumberForm {
    width: 80%;
    
    
}

.form{
    display: flex;
    align-items: center;
    position: relative;
    height: 110vh;
    width: 94vw;
    z-index: 0;
    min-width: min-content;
    min-height: max-content;
}
.formMain{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 0 0 0 100px;
    width: 38vw;
    height: 40vw;
    z-index: 2;
    background: rgba(255, 255, 255, 0.89);
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}
.donate-main-button{
    
    border: none;
    align-self: center;
    padding: 0 100px 0 100px;
    height: 60px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #6BB6A1;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    transition: 700ms;
}
.donate-main-button:hover{
    background: #4b9681;
    transition: 700ms;
}
.donateNumberForm{
    background: rgba(255, 255, 255, 0.59);
    box-shadow: 0px 1px 4px 3px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}
.donateMainText{
    text-align: center;
    width: 91%;
    font-family: 'Arial';
    font-style: normal;
    font-size: 16px;
    line-height: 20px;

}

@media (max-device-width: 550px) {
    .formMain{
        margin: 0 0 0 100px;
        width: 80vw;
        height: 90vw;
        align-items: center;
    }
    .ImgMain{
        width: 100vw;
    }
    .donate-main-button{
        height: 100px;
        text-align: center;
        width: 100%;
    }
    .donate-main-button p{
        font-size: 50px;
    }
    .donateTitle{
        font-size: 60px;
        margin-top: 20px;
    }
    
    .donateNumberForm div{
        font-size: 60px;
    }
    .donateNumberForm label{
        display: none;
    }
    .donateMainText{
        font-size: 30px;
        line-height: 37px;
        font-weight: 400;
    }
}