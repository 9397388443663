.PoorhousePagePeople{
    margin: 20px 0 20px 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}
.PoorhousePagePeople2{
    margin: 20px 0 20px 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
}
.Img{
    width: 50vw;
    object-fit: contain;
    min-width: 300px;
    border-radius: 10px;
    
}
.MainText{
    width: 40vw;
    min-width: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 20px 0 20px;
    
}
.Title{
    width: 40vw;
    text-align: center;
    height: min-content;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 49px;
    color: #000000;
    
}
.Text{
    width: 40vw;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    color: #000000;
    text-align: center;
    margin: 20px 0 20px 0;
}
@media (max-width:700px) {
    .PoorhousePagePeople{
        display: flex;
        flex-direction: column-reverse;
    }
    .MainText{
        margin:0;
    }
    .PoorhousePagePeople2{
        display: flex;
        flex-direction: column;
    }
    .Text{
        width: 96vw;
        
    }
    .Img{
        width: 96vw;
    }
    
}
@media (max-width:500px) {
    .Title{  
        font-size: 24px;
        line-height: 27px;
        font-weight: 700;
        width: min-content;
    }
    
}

@media (max-device-width:450px) {
    .PoorhousePagePeople{
        
        display: flex;
        flex-direction: column-reverse;
    }
    .MainText{
        margin:0;
    }
    .PoorhousePagePeople2{
        display: flex;
        flex-direction: column;
    }
    .Text{
        width: 96vw;
        font-size: 14px;
        line-height: 20px;
    }
    .Title{
        margin: 30px 0 10px 0;
        font-size: 60px;
        width: min-content;
    }
    .Img{
        width: 96vw;
    }
}