body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import "~react-image-gallery/styles/css/image-gallery.css";

@font-face {
	font-family: 'Vezitsa'; 
	src: url(./components/UI/fonts/vezitsacyrillic.ttf); 
}

::-webkit-scrollbar {
  width: 10px; /* ширина для вертикального скролла */
  height: 10px; /* высота для горизонтального скролла */
  background-color: #c0c0c0;
}

/* ползунок скроллбара */
::-webkit-scrollbar-thumb {
  background-color: #4e4e4e;
  border-radius: 9px;
  
}



