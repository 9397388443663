.main-button-contener{
    position: relative;
    display: flex;
    justify-content: center;
    margin: 60px 0 60px 0 ;
}


.main-button{

    width: 456px;
    height: 90px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #6BB6A1;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    transition: 700ms;
}
.main-button:hover{
    background: #4b9681;
    transition: 700ms;
}
.main-button-text{
    
    text-decoration: none;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 34px;
    color: #FFFFFF;
}

.main-button-navlink{
    text-decoration: none;

}
@media (max-device-width: 500px) {
    .main-button-text{
        font-size: 24px;
    }
    .main-button{
        width: 400px;
        height: 90px;
    }
}
@media (min-width: 1500px) {
    
    .main-button{
        width: 456px;
        height: 110px;
    }
}