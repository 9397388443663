.ContactsPage {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
}

.Info {
    margin: 30px;
}
.Info1{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
}
.Text {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    color: #000000;

}
.Text1 {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 34px;
    color: #000000;

}
.Link{
    color: #000000;
}
.Link:hover{
    transition: 700ms;
    color: #c93600;
}
.TextT{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}
.Title{
    margin: 40px 0 40px 0;
    font-family: 'Vezitsa';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 26px;
    color: #000000;
}
.ContactsPageMain{
    background: #FFFFFF;
    box-shadow: 0px 4px 4px 3px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 94vw;
    margin: 40px 3vw 40px 3vw;
   
}
.Img1{
    object-fit: cover;
    width: 40px;
    height: 40px;
}
.Img2{
    object-fit: object-fit;
    width: 65px;
    height: 70px;
    margin-top: -2px;
}

.BusImg{
    width: 40px;
    object-fit: contain;
}
.Geoposition{
    margin: 40px 3vw 40px 3vw;
}
.GeopositionTitle{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 0 0 20px 0;
}
.Line{
    width: 40vw;
   
}
.MapInfomation{
    display: flex;
    flex-flow: row nowrap;
}
.Map{
    object-fit: cover;
    border-radius: 10px;
    width: 50vw;
    height: 25vw;
    min-width: 300px;
    min-height: 300px;
}
.geoText{
    max-width: 1000px;
    width: 40%;
    line-height: 54px;
}
.Address{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.Button{
    width: 80%;
    max-width: 350px;
    
    text-decoration: none;
    text-align: center;
    background: #DF6033;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
}
.Button:hover{
    transition: 700ms;
    background: #c93600;
}
.WhiteText{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    line-height: 10px;
    color: #FFFFFF;
    
}

@media (max-device-width: 500px) {
    .BusImg{
        width: 100px;
    }
    .Title{
        margin: 10px 0 10px 0;
        font-family: 'Vezitsa';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 26px;
        color: #000000;
    }
    .Text {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 34px;
        color: #000000;
    
    }
    .Text1 {
        font-family: 'Arial';
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 34px;
        color: #000000;
    
    }
    .ContactsPageMain{
        margin: 0 3vw 40px 3vw;
    }
    .MapInfomation{
        
        display: flex;
        flex-flow: column nowrap;
        height: min-content;
    }
    .Map{
        align-self: center;
        width: 94vw;
        height: 50vw;
    }
    .Button{
        width: 400px;
        height: 80px;
        align-items: center;
    }
    .WhiteText{   
        font-size: 28px;
        line-height: 20px;
        color: #FFFFFF;
    }
    .geoText{
        min-width: 300px;
        text-align: center;
    }
    .Text1{
        font-size: 15px;
    }
}
@media (min-width: 1500px){
    .Button{
        width: 432px;
        height: 88px;}
    .WhiteText{
            font-family: 'Arial';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 34px;
            color: #FFFFFF;
        }
    .Address{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;
        }
}