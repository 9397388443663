.HistoryPage {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    
   margin: 0 0 20px 0;
}
.Title {
    font-family: 'Vezitsa';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 26px;
    color: #000000;
    margin: 0 0 0 5px;
}
.HistorySectionfirst{
    display: flex;
    flex-flow: column ;
    margin: 40px 3vw 40px 3vw;
}
.HistorySection1{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    margin: 40px 0 20px 0;
    
}
.text{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    color: #000000;
}
.text1 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    text-align: center;
    max-width: 48%;
    min-width: 250px;
    }

.img1 {
    border-radius: 5px;
    object-fit: cover;
    width: 100%;
    min-width: 250px;
    max-width: 50%;
}

.HistorySection2{
    align-self: center;
    background: #FFFFFF;
    border-radius: 5px;
    display: flex;
    flex-flow: row-reverse wrap;
    justify-content: center;
    margin: 40px 3vw 40px 3vw;
}
.HistorySection3{
    background: #494949;
    border-radius: 5px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
}
.text3{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    text-align: center;
    max-width: 33%;
    min-width: 250px;
    margin: 0 1% 0 1%;
    color: white;}

.img3{
    margin: 20px 0 20px 0;
    border-radius: 5px;
    object-fit: cover;
    width: 100%;
    min-width: 250px;
    max-width: 60%;
}
.textWhite{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    color: #ffffff;
}

.HistorySection4{
    margin: 60px 3vw 40px 3vw;
    display: flex;
    flex-direction: column;

}
.img4{
    margin: 40px 0 40px 0;
    border-radius: 5px;
    object-fit: cover;
    width: 100%;
    min-width: 250px;
    max-width: 100%;
}
.text4{
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 34px;
    color: #000000;
    width: 100%;


}

@media (max-device-width: 450px) {
    .Title{
        margin: 30px 0 20px 0;
        font-size: 60px;
    }
    .textWhite{
        font-size: 13px;
    }
    .text{
        font-size: 13px;
        text-align: center;
    }

    .HistorySection1{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .img1{
        max-width: 94vw;
    }
    .text1{
        max-width: 94vw;
        font-size: 13px;
    }
    .HistorySection3{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    .img3{
        max-width: 94vw;
    }
    .text3{
        max-width: 94vw;
        font-size: 13px;
    }
    .text4{
        font-size: 13px;
    }
}