.Header {
    margin: 20px 3vw 20px 3vw;
    display: flex;
    flex-flow: column wrap;
    
}
.navlinkLogo {
    text-decoration: none;

}
.logo {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    
}
.logoImg {
    width: 235px;
    height: 215px;
    
}
.logoTitle {
    font-family: 'Vezitsa';
    font-style: normal;
    font-weight: 400;
    font-size:  27px;
    width: 70%;
    color: #2DA583;

}
.orangeLine{
    width: 100%;
    height: 4px;
    background: #DF6033;
    border-radius: 20px;
    border:none;
}
.menu {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    text-align: center;
    align-content: center;
    align-items: center;
    height: min-content;
    min-width: 500px;
}

.menuItem {
    font-family: 'Vezitsa';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-decoration: none;
    color: #000000;
    margin: 0 20px 0 20px;
    
}
.active {
    color: #DF6033;
    font-family: 'Vezitsa';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-decoration: none;
}
.line{
    width: 100%;
}
@media  ( max-device-width: 490px) {
    

}
@media  ( max-device-width: 400px) {
    .menu {
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-end;
        
        text-align: center;
        align-content:space-around;
        max-height: 100px;
        height: min-content;
        
    }
    .menuItem:last-child{
        margin: 30px 0 0 0 ;
    }
    .menuItem{
        font-size: 27px;
    }
    .active:last-child{
        font-size: 27px;
        margin: 30px 0 0 0 ;
    }
    .active{
        font-size: 27px;
    }
}